import React, { Suspense, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barThickness: 1,
  shadowBlur: 5,
});

// PAGES
const ErrorPage = React.lazy(() => import("../pages/Error/ErrorsPage"));

const PrivateRoutes: React.FC = () => {
  const menus = useSelector((state: any) => state.menus.menus);

  const routes = useMemo(() => {
    const allRoutes = [
      //Gerais
      {
        path: "/start",
        element: React.lazy(() => import("../pages/Home/Home")),
        private: false,
      },
      {
        path: "/profile",
        element: React.lazy(() => import("../pages/Profile/Profile")),
        private: false,
      },
      //Manutenção
      {
        path: "/maintenance/users",
        element: React.lazy(() => import("../pages/Maintenance/Users/Users")),
        private: true,
      },
      {
        path: "/maintenance/users/:id",
        element: React.lazy(
          () => import("../pages/Maintenance/Users/UserDetail")
        ),
        private: true,
      },
      {
        path: "/maintenance/menus",
        element: React.lazy(() => import("../pages/Maintenance/Menus/Menus")),
        private: true,
      },
      {
        path: "/maintenance/user-menus",
        element: React.lazy(
          () => import("../pages/Maintenance/Menus/MenusByUser")
        ),
        private: true,
      },
      {
        path: "/maintenance/menu-users",
        element: React.lazy(
          () => import("../pages/Maintenance/Menus/UsersByMenu")
        ),
        private: true,
      },
      {
        path: "/maintenance/rotas",
        element: React.lazy(() => import("../pages/Maintenance/Rotas/Rotas")),
        private: true,
      },
      {
        path: "/maintenance/seasons",
        element: React.lazy(
          () => import("../pages/Maintenance/Seasons/Seasons")
        ),
        private: true,
      },
      {
        path: "/maintenance/collections-by-user",
        element: React.lazy(
          () => import("../pages/Maintenance/Seasons/ByUser")
        ),
        private: true,
      },
      {
        path: "/maintenance/samples-by-user",
        element: React.lazy(
          () => import("../pages/Maintenance/Samples/ByUser")
        ),
        private: true,
      },
      //Lista telefónica
      {
        path: "/maintenance/rotas",
        element: React.lazy(() => import("../pages/Maintenance/Rotas/Rotas")),
        private: true,
      },
      {
        path: "/phonebook/list",
        element: React.lazy(
          () => import("../pages/Maintenance/Phonebook/List")
        ),
        private: true,
      },
      {
        path: "/phonebook/maintenance",
        element: React.lazy(
          () => import("../pages/Maintenance/Phonebook/Maintenance")
        ),
        private: true,
      },
      {
        path: "/phonebook/list/:id",
        element: React.lazy(
          () => import("../pages/Maintenance/Phonebook/ContactDetails")
        ),
        private: true,
      },
      //Contactos Website
      {
        path: "/website-contacts",
        element: React.lazy(
          () => import("../pages/ContactosWebsite/ContactosWebsite")
        ),
        private: true,
      },
      //DRH - Portal
      {
        path: "/drh/portal/communications",
        element: React.lazy(
          () => import("../pages/DRH/Portal/Comunicacoes/Comunicacoes")
        ),
        private: true,
      },
      {
        path: "/drh/portal/communications/:id",
        element: React.lazy(
          () =>
            import("../pages/PortalColaborador/Comunicacoes/DetalheComunicacao")
        ),
        private: true,
      },
      {
        path: "/drh/portal/communications/messages/:id",
        element: React.lazy(
          () => import("../pages/DRH/Portal/Comunicacoes/ListaMensagens")
        ),
        private: "/drh/portal/communications",
      },
      {
        path: "/drh/portal/communications/history/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/PortalColaborador/Comunicacoes/HistoricoComunicacao"
            )
        ),
        private: "/drh/portal/communications",
      },
      {
        path: "/drh/portal/maintenance",
        element: React.lazy(
          () => import("../pages/DRH/Portal/Manutencao/Manutencao")
        ),
        private: true,
      },
      {
        path: "/drh/portal/data-change-request",
        element: React.lazy(
          () => import("../pages/DRH/Portal/PedidosAlteracao")
        ),
        private: true,
      },
      //DRH - Iniciativas
      {
        path: "/drh/actions",
        element: React.lazy(
          () => import("../pages/DRH/Iniciativas/Iniciativas")
        ),
        private: true,
      },
      {
        path: "/drh/actions/:id",
        element: React.lazy(
          () => import("../pages/DRH/Iniciativas/IniciativaDetails")
        ),
        private: true,
      },
      //DRH - Questionários
      {
        path: "/drh/forms",
        element: React.lazy(
          () => import("../pages/DRH/Questionarios/ListaQuestionarios")
        ),
        private: true,
      },
      {
        path: "/drh/forms/:id",
        element: React.lazy(
          () => import("../pages/DRH/Questionarios/DetalheQuestionario")
        ),
        private: true,
      },
      //DRH - Entrevistas Saida
      {
        path: "/drh/interviews/exit",
        element: React.lazy(
          () => import("../pages/DRH/EntrevistaSaida/ListaEntrevistaSaida")
        ),
        private: true,
      },
      {
        path: "/drh/interviews/exit/:id",
        element: React.lazy(
          () => import("../pages/DRH/EntrevistaSaida/DetalheEntrevistaSaida")
        ),
        private: true,
      },
      //DRH - Candidaturas
      {
        path: "/drh/candidates",
        element: React.lazy(
          () => import("../pages/DRH/Candidaturas/Candidaturas")
        ),
        private: true,
      },
      //DRH - Ficha do colaborador
      {
        path: "/drh/employees",
        element: React.lazy(
          () => import("../pages/DRH/FichasColaborador/FichasColaborador")
        ),
        private: true,
      },
      //DRH - Posto médico
      {
        path: "/clinic/appointments",
        element: React.lazy(
          () => import("../pages/PostoMedico/ListaConsultas")
        ),
        private: true,
      },
      {
        path: "/clinic/appointments/add",
        element: React.lazy(
          () => import("../pages/PostoMedico/MarcarConsulta")
        ),
        private: "/clinic/appointments",
      },
      {
        path: "/clinic/appointments/:id",
        element: React.lazy(
          () => import("../pages/PostoMedico/DetalheConsulta")
        ),
        private: true,
      },
      {
        path: "/clinic/maintenance",
        element: React.lazy(
          () => import("../pages/PostoMedico/ManutencaoPostoMedico")
        ),
        private: true,
      },
      //DRH - Formações
      {
        path: "/trainings/list",
        element: React.lazy(() => import("../pages/DRH/Formacoes/Lista/Lista")),
        private: true,
      },
      {
        path: "/trainings/list/:id",
        element: React.lazy(
          () => import("../pages/DRH/Formacoes/Lista/Formacao")
        ),
        private: true,
      },

      {
        path: "/trainings/maintenance",
        element: React.lazy(
          () => import("../pages/DRH/Formacoes/Manutencao/Manutencao")
        ),
        private: true,
      },
      {
        path: "/trainings/summary",
        element: React.lazy(
          () =>
            import("../pages/DRH/Formacoes/SumariosPresencas/SumariosPresencas")
        ),
        private: true,
      },
      //DRH - Formações - Relatórios
      {
        path: "/trainings/reports",
        element: React.lazy(
          () => import("../pages/DRH/Formacoes/Relatorios/Relatorios")
        ),
        private: true,
      },
      {
        path: "/trainings/reports/hours",
        element: React.lazy(
          () =>
            import("../pages/DRH/Formacoes/Relatorios/Conteudo/HorasFormacao")
        ),
        private: true,
      },
      {
        path: "/trainings/reports/general",
        element: React.lazy(
          () =>
            import("../pages/DRH/Formacoes/Relatorios/Conteudo/RelatorioGeral")
        ),
        private: true,
      },
      {
        path: "/trainings/reports/evaluations",
        element: React.lazy(
          () =>
            import(
              "../pages/DRH/Formacoes/Relatorios/Conteudo/RelatorioExecucaoAvaliacoes"
            )
        ),
        private: true,
      },
      //DRH - Formações - Plano
      {
        path: "/trainings/plan",
        element: React.lazy(
          () => import("../pages/DRH/Formacoes/Plano/PlanoLista")
        ),
        private: true,
      },
      {
        path: "/trainings/plan/:id",
        element: React.lazy(
          () => import("../pages/DRH/Formacoes/Plano/PlanoFormacao")
        ),
        private: true,
      },
      //DRH - Recrutamento
      {
        path: "/drh/recruitment/jobs",
        element: React.lazy(
          () => import("../pages/DRH/Recrutamento/Vagas/Vagas")
        ),
        private: true,
      },
      {
        path: "/drh/recruitment/jobs/new",
        element: React.lazy(
          () => import("../pages/DRH/Recrutamento/Vagas/VagaDetalhe")
        ),
        private: "/drh/recruitment/jobs",
      },
      {
        path: "/drh/recruitment/jobs/:id",
        element: React.lazy(
          () => import("../pages/DRH/Recrutamento/Vagas/VagaDetalhe")
        ),
        private: true,
      },

      {
        path: "/drh/recruitment/candidates",
        element: React.lazy(
          () => import("../pages/DRH/Recrutamento/Candidatos/Candidatos")
        ),
        private: true,
      },
      {
        path: "/drh/recruitment/candidates/:id",
        element: React.lazy(
          () => import("../pages/DRH/Recrutamento/Candidatos/CandidatoDetalhe")
        ),
        private: true,
      },
      {
        path: "/drh/recruitment/maintenance",
        element: React.lazy(
          () => import("../pages/DRH/Recrutamento/Manutencao")
        ),
        private: true,
      },
      {
        path: "/new-onboarding/:id",
        element: React.lazy(
          () => import("../pages/DRH/Onboarding/List/NewOnboarding")
        ),
        private: "/onboarding",
      },
      //DRH - Pedidos Admissão
      {
        path: "/drh/admission-applications/list",
        element: React.lazy(
          () =>
            import("../pages/DRH/PedidosAdmissao/Lista/PedidosAdmissaoLista")
        ),
        private: true,
      },
      {
        path: "/drh/admission-applications/list/:id",
        element: React.lazy(
          () => import("../pages/DRH/PedidosAdmissao/Lista/PedidoAdmissao")
        ),
        private: true,
      },
      {
        path: "/drh/admission-applications/maintenance",
        element: React.lazy(
          () =>
            import(
              "../pages/DRH/PedidosAdmissao/Manutencao/PedidosAdmissaoManutencao"
            )
        ),
        private: true,
      },
      //DRH - Clima organizacional
      {
        path: "/drh/organization",
        element: React.lazy(
          () => import("../pages/DRH/ClimaOrganizacional/ClimaOrganizacional")
        ),
        private: true,
      },
      //DRH - Avaliação de Flexibilidade Horária e Regimes Híbridos
      {
        path: "/drh/avaliacao-flexibilidade-regime-hibrido",
        element: React.lazy(
          () =>
            import(
              "../pages/DRH/AvaliacaoImpactoFlexibilidade/AvaliacaoImpactoFlexibilidade"
            )
        ),
        private: true,
      },

      //Onboarding
      {
        path: "/onboarding/list",
        element: React.lazy(
          () => import("../pages/DRH/Onboarding/List/ListOnboarding")
        ),
        private: true,
      },
      {
        path: "/onboarding/list/:id",
        element: React.lazy(
          () => import("../pages/DRH/Onboarding/List/Onboarding")
        ),
        private: true,
      },
      {
        path: "/onboarding/maintenance",
        element: React.lazy(
          () => import("../pages/DRH/Onboarding/Maintenance/Maintenance")
        ),
        private: true,
      },
      {
        path: "/onboarding/tasks",
        element: React.lazy(
          () => import("../pages/DRH/Onboarding/Tasks/Tasks")
        ),
        private: true,
      },
      {
        path: "/onboarding/home",
        element: React.lazy(() => import("../pages/DRH/Onboarding/Home/Home")),
        private: true,
      },
      {
        path: "/onboarding/my-data",
        element: React.lazy(
          () => import("../pages/DRH/Onboarding/MyData/MyData")
        ),
        private: true,
      },
      //Rpeople
      {
        path: "/rpeople/evaluations",
        element: React.lazy(() => import("../pages/RPeople/Avaliacao")),
        private: true,
      },
      {
        path: "/rpeople/evaluations/:id",
        element: React.lazy(
          () => import("../pages/RPeople/QuestionarioAvaliacao")
        ),
        private: true,
      },
      {
        path: "/rpeople/sector-evaluations",
        element: React.lazy(() => import("../pages/RPeople/AvaliacaoSetores")),
        private: true,
      },
      {
        path: "/rpeople/sector-evaluations/:id",
        element: React.lazy(
          () => import("../pages/RPeople/QuestionarioAvaliacaoSetores")
        ),
        private: true,
      },
      {
        path: "/rpeople/extraordinary-evaluations",
        element: React.lazy(
          () => import("../pages/RPeople/AvaliacoesExtraordinarias")
        ),
        private: true,
      },
      {
        path: "/rpeople/extraordinary-evaluations/add",
        element: React.lazy(
          () => import("../pages/RPeople/CriarAvaliacaoExtraordinaria")
        ),
        private: "/rpeople/extraordinary-evaluations",
      },
      {
        path: "/rpeople/maintenance",
        element: React.lazy(
          () => import("../pages/RPeople/Manutencao/R_peopleManutencao")
        ),
        private: true,
      },
      //Rpeople - Relatórios
      {
        path: "/rpeople/reports",
        element: React.lazy(
          () => import("../pages/RPeople/Relatorios/Relatorios")
        ),
        private: true,
      },
      //Rpeople - Relatórios - Avaliação Qualitativa
      {
        path: "/rpeople/reports/qualitative/feedback-confirmations",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/ConfirmacoesFeedback"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/qualitative/agregated-evaluation",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/ConsultaAgregada"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/qualitative/evaluations-evolution",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/AvaliacoesEvolucao"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/qualitative/states",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/EstadoAvaliacoes"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/qualitative/sector-evolution",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/EvolucaoSetor"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/qualitative/training",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/NecessidadeFormacao"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/qualitative/evaluator-proposals",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/PropostasFormacaoAvaliador"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/qualitative/global-report",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoQualitativa/RelatorioGlobal"
            )
        ),
        private: true,
      },
      //Rpeople - Relatórios - Avaliação Setores
      {
        path: "/rpeople/reports/sectors/evaluations-evolution",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoSetores/AvaliacoesEvolucao"
            )
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/sectors/evaluations-state",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Relatorios/AvaliacaoSetores/EstadoAvaliacoes"
            )
        ),
        private: true,
      },
      //Rpeople - Relatórios - Gerais
      {
        path: "/rpeople/reports/general/global-average",
        element: React.lazy(
          () =>
            import("../pages/RPeople/Relatorios/Gerais/MediaGlobalAvaliacoes")
        ),
        private: true,
      },
      {
        path: "/rpeople/reports/general/evaluators-evaluated",
        element: React.lazy(
          () =>
            import("../pages/RPeople/Relatorios/Gerais/AvaliadoresAvaliados")
        ),
        private: true,
      },
      //Rpeople - Histórico
      {
        path: "/rpeople/historic",
        element: React.lazy(
          () => import("../pages/RPeople/Historico/Historico")
        ),
        private: true,
      },
      {
        path: "/rpeople/historic/consult",
        element: React.lazy(
          () => import("../pages/RPeople/Historico/Pages/ConsultarAvaliacoes")
        ),
        private: true,
      },
      {
        path: "/rpeople/historic/consult/my-sector",
        element: React.lazy(
          () => import("../pages/RPeople/Historico/Pages/AvaliacaoMeuSetor")
        ),
        private: true,
      },
      {
        path: "/rpeople/historic/consult/sectors",
        element: React.lazy(
          () =>
            import(
              "../pages/RPeople/Historico/Pages/ConsultarAvaliacoesSetores"
            )
        ),
        private: true,
      },
      //Rpeople - Simulador
      {
        path: "/rpeople/simulator",
        element: React.lazy(
          () => import("../pages/RPeople/Simulador/SimuladorPremio")
        ),
        private: true,
      },
      //Portal Colaborador
      {
        path: "/employeeportal",
        element: React.lazy(
          () => import("../pages/PortalColaborador/PortalColaborador")
        ),
        private: true,
      },
      {
        path: "/employeeportal/team",
        element: React.lazy(
          () => import("../pages/PortalColaborador/MinhaEquipa/MinhaEquipa")
        ),
        private: true,
      },
      {
        path: "/employeeportal/evaluation",
        element: React.lazy(
          () =>
            import(
              "../pages/PortalColaborador/AvaliacaoDesempenho/AvaliacaoDesempenho"
            )
        ),
        private: true,
      },
      {
        path: "/employeeportal/evaluation/form/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/PortalColaborador/AvaliacaoDesempenho/QuestionarioAvaliacao"
            )
        ),
        private: "/employeeportal/evaluation",
      },
      {
        path: "/employeeportal/communications",
        element: React.lazy(
          () => import("../pages/PortalColaborador/Comunicacoes/Comunicacoes")
        ),
        private: true,
      },
      {
        path: "/employeeportal/communications/new",
        element: React.lazy(
          () =>
            import("../pages/PortalColaborador/Comunicacoes/DetalheComunicacao")
        ),
        private: "/employeeportal/communications",
      },
      {
        path: "/employeeportal/communications/history/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/PortalColaborador/Comunicacoes/HistoricoComunicacao"
            )
        ),
        private: "/employeeportal/communications",
      },
      {
        path: "/employeeportal/trainings",
        element: React.lazy(
          () => import("../pages/PortalColaborador/Formacoes/Formacoes")
        ),
        private: true,
      },
      {
        path: "/employeeportal/users",
        element: React.lazy(
          () => import("../pages/PortalColaborador/UserData/UserData")
        ),
        private: true,
      },
      {
        path: "/employeeportal/earnings",
        element: React.lazy(
          () => import("../pages/PortalColaborador/Vencimentos/Vencimentos")
        ),
        private: true,
      },
      {
        path: "/employeeportal/documents",
        element: React.lazy(
          () => import("../pages/PortalColaborador/Documentos/Documentos")
        ),
        private: "/employeeportal",
      },
      //RGPD
      {
        path: "/rgpd/list",
        element: React.lazy(() => import("../pages/Rgpd/RegistoProcessos")),
        private: true,
      },
      //RGPD
      {
        path: "/rgpd/violacao-dados/list",
        element: React.lazy(
          () => import("../pages/Rgpd/RgpdViolacaoDeDados/Lista")
        ),
        private: true,
      },
      {
        path: "/rgpd/violacao-dados/list/:id",
        element: React.lazy(
          () => import("../pages/Rgpd/RgpdViolacaoDeDados/Detalhe")
        ),
        private: true,
      },
      {
        path: "/rgpd/list/:id",
        element: React.lazy(() => import("../pages/Rgpd/RegistoDetalhe")),
        private: true,
      },

      //ConteudosFrontend
      {
        path: "/conteudos-frontend/ementa",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Ementa/Ementa"
            )
        ),
        private: true,
      },
      {
        path: "/conteudos-frontend/ementa/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Ementa/Ementa"
            )
        ),
        private: true,
      },
      {
        path: "/conteudos-frontend/noticias",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Noticias/Noticias"
            )
        ),
        private: true,
      },
      {
        path: "/conteudos-frontend/noticias/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Noticias/Detalhe"
            )
        ),
        private: "/conteudos-frontend/noticias",
      },
      {
        path: "/conteudos-frontend/galerias",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Galerias/Lista"
            )
        ),
        private: true,
      },
      {
        path: "/conteudos-frontend/galerias/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Galerias/Detalhe"
            )
        ),
        private: true,
      },
      //Gestão Energia
      {
        path: "/gestao-energia/dashboards",
        element: React.lazy(() => import("../pages/GestaoEnergia/Dashboards")),
        private: true,
      },
      {
        path: "/gestao-energia/partilhas",
        element: React.lazy(
          () => import("../pages/GestaoEnergia/Partilhas/Partilhas")
        ),
        private: true,
      },
      {
        path: "/gestao-energia/configuracoes/manutencoes",
        element: React.lazy(
          () =>
            import("../pages/GestaoEnergia/Configuracoes/Manutencao/Manutencao")
        ),
        private: "/gestao-energia/configuracoes",
      },
      {
        path: "/gestao-energia/configuracoes/contadores",
        element: React.lazy(
          () => import("../pages/GestaoEnergia/Contadores/Contadores")
        ),
        private: "/gestao-energia/configuracoes",
      },
      {
        path: "/gestao-energia/registos",
        element: React.lazy(
          () => import("../pages/GestaoEnergia/Registos/Registos")
        ),
        private: true,
      },
      {
        path: "/gestao-energia/configuracoes/contador",
        element: React.lazy(
          () => import("../pages/GestaoEnergia/Contadores/CriarContador")
        ),
        private: "/gestao-energia/configuracoes",
      },
      {
        path: "/gestao-energia/configuracoes/contadores/:id",
        element: React.lazy(
          () => import("../pages/GestaoEnergia/Contadores/CriarContador")
        ),
        private: "/gestao-energia/configuracoes",
      },
      {
        path: "/gestao-energia/dashboard/private/:id",
        element: React.lazy(
          () => import("../pages/GestaoEnergia/DashboardPrivate")
        ),
        private: "/gestao-energia/dashboards",
      },
      //RKPI
      {
        path: "/rkpi/list",
        element: React.lazy(() => import("../pages/Rkpi/Dashboards/Dashboard")),
        private: true,
      },
      {
        path: "/rkpi/maintenance",
        element: React.lazy(
          () => import("../pages/Rkpi/Manutencao/Manutencao")
        ),
        private: true,
      },
      //Relatórios Logistica
      {
        path: "/logistic-reports/:id",
        element: React.lazy(
          () => import("../pages/RelatoriosLogistica/Dashboards")
        ),
        private: false,
      },
      //RMIND
      {
        path: "/rmind/dashboard/:id",
        element: React.lazy(() => import("../pages/Rmind/Dashboard")),
        private: false,
      },
      //KAIZEN
      {
        path: "/kaizen/:id",
        element: React.lazy(() => import("../pages/Kaizen/Dashboard")),
        private: false,
      },
      //Comunicação Empresarial
      {
        path: "/business-communication/historic",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ArquivoHistorico/ArquivoHistorico"
            )
        ),
        private: true,
      },
      //Manutenção Industrial
      {
        path: "/manutencao-industrial/dashboards/:id",
        element: React.lazy(
          () => import("../pages/ManutencaoIndustrial/Dashboard/Dashboard")
        ),
        private: false,
      },
      {
        path: "/notas-pm/list",
        element: React.lazy(
          () => import("../pages/ManutencaoIndustrial/notas-pm/NotasPm")
        ),
        private: false,
      },
      {
        path: "/biomassa/manutencao",
        element: React.lazy(
          () =>
            import(
              "../pages/ManutencaoIndustrial/Biomassa/Manutencao/Manutencao"
            )
        ),
        private: false,
      },
      {
        path: "/biomassa/lista",
        element: React.lazy(
          () => import("../pages/ManutencaoIndustrial/Biomassa/List")
        ),
        private: false,
      },
      {
        path: "/biomassa/lista/:id",
        element: React.lazy(
          () => import("../pages/ManutencaoIndustrial/Biomassa/Detalhe")
        ),
        private: false,
      },
      //PG
      {
        path: "/pg/list",
        element: React.lazy(() => import("../pages/PG/Lista")),
        private: true,
      },
      {
        path: "/pg/list/:id",
        element: React.lazy(() => import("../pages/PG/Detalhe")),
        private: true,
      },
      //Contratos Fornecedor
      {
        path: "/contratos/manutencao",
        element: React.lazy(
          () => import("../pages/Contratos/Manutencao/ConfiguracoesManutencao")
        ),
        private: true,
      },
      {
        path: "/contratos/list",
        element: React.lazy(() => import("../pages/Contratos/Lista")),
        private: true,
      },
      {
        path: "/contratos/list/:id",
        element: React.lazy(() => import("../pages/Contratos/Detalhe")),
        private: true,
      },
      // Rfs / Shopfloor
      {
        path: "/rfs/shopfloor/maintenance",
        element: React.lazy(
          () => import("../pages/Rfs/Shopfloor/Manutencao/ShopfloorManutencao")
        ),
        private: true,
      },
      {
        path: "/rfs/shopfloor/orders",
        element: React.lazy(
          () => import("../pages/Rfs/Shopfloor/Ordens/ShopfloorOrdens")
        ),
        private: true,
      },
      {
        path: "/rfs/shopfloor/orders/:id",
        element: React.lazy(
          () => import("../pages/Rfs/Shopfloor/Ordens/DetalheOrdem")
        ),
        private: true,
      },
      {
        path: "/rfs/shopfloor/dashboard",
        element: React.lazy(() => import("../pages/Rfs/Shopfloor/Dashboards")),
        private: true,
      },
      {
        path: "/rfs/shopfloor/dashboard/:id",
        element: React.lazy(
          () => import("../pages/Rfs/Shopfloor/DashboardOrdem")
        ),
        private: true,
      },

      {
        path: "/rfs/quality_Controllers",
        element: React.lazy(
          () =>
            import(
              "../pages/Rfs/QualityControllers/Maintenance/QualityControllers"
            )
        ),
        private: true,
      },

      {
        path: "/sales",
        element: React.lazy(() => import("../pages/Vendas/Dashboard")),
        private: true,
      },
      //Solicitações
      {
        path: "/requests/roaming",
        element: React.lazy(() => import("../pages/Requests/Roaming/Roaming")),
        private: "/requests/roaming",
      },
      {
        path: "/requests/roaming/:id",
        element: React.lazy(
          () => import("../pages/Requests/Roaming/NewRequest")
        ),
        private: "/requests/roaming",
      },
      {
        path: "/requests/vehicles",
        element: React.lazy(() => import("../pages/Requests/Vehicles/List")),
        private: true,
      },
      {
        path: "/requests/vehicles/:id",
        element: React.lazy(
          () => import("../pages/Requests/Vehicles/CreateRequest")
        ),
        private: "/requests/vehicles",
      },
      {
        path: "/requests/rooms",
        element: React.lazy(() => import("../pages/Requests/Rooms/List")),
        private: true,
      },
      {
        path: "/requests/rooms/:id",
        element: React.lazy(
          () => import("../pages/Requests/Rooms/CreateRequest")
        ),
        private: "/requests/rooms",
      },
      {
        path: "/requests/requisicoes",
        element: React.lazy(() => import("../pages/Requests/Requisicoes/List")),
        private: true,
      },
      {
        path: "/requests/requisicoes/:id",
        element: React.lazy(
          () => import("../pages/Requests/Requisicoes/CreateRequest")
        ),
        private: true,
      },
      {
        path: "/requests/reparacoes",
        element: React.lazy(() => import("../pages/Requests/Reparacoes/List")),
        private: true,
      },
      {
        path: "/requests/reparacoes/:id",
        element: React.lazy(
          () => import("../pages/Requests/Reparacoes/CreateRequest")
        ),
        private: true,
      },
      {
        path: "/requests/material-informatico",
        element: React.lazy(
          () => import("../pages/Requests/MaterialInformatico/List")
        ),
        private: true,
      },
      {
        path: "/requests/material-informatico/:id",
        element: React.lazy(
          () => import("../pages/Requests/MaterialInformatico/CreateRequest")
        ),
        private: true,
      },
      {
        path: "/requests/wifi",
        element: React.lazy(() => import("../pages/Requests/Wifi/List")),
        private: true,
      },
      {
        path: "/requests/wifi/:id",
        element: React.lazy(
          () => import("../pages/Requests/Wifi/CreateRequest")
        ),
        private: true,
      },
      {
        path: "/requests/maintenance/rooms",
        element: React.lazy(
          () => import("../pages/Requests/Manutencao/Salas/SalasManutencao")
        ),
        private: true,
      },
      {
        path: "/requests/car-wash",
        element: React.lazy(
          () => import("../pages/Requests/Carwash/Utilizador")
        ),
        private: true,
      },
      {
        path: "/requests/gestor/car-wash",
        element: React.lazy(() => import("../pages/Requests/Carwash/Gestor")),
        private: true,
      },
      {
        path: "/requests/maintenance/car-wash",
        element: React.lazy(
          () =>
            import(
              "../pages/Requests/Manutencao/LavagemAuto/LavagemAutoManutencao"
            )
        ),
        private: true,
      },
      {
        path: "/conteudos-frontend/sugestoes",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Sugestoes/Sugestoes"
            )
        ),
        private: true,
      },
      {
        path: "/conteudos-frontend/sugestoes/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/ComunicacaoEmpresarial/ConteudosFrontend/Sugestoes/Detalhe"
            )
        ),
        private: true,
      },
      {
        path: "/requests/maintenance/configuracoes",
        element: React.lazy(
          () =>
            import(
              "../pages/Requests/Manutencao/ConfiguracoesGlobais/ConfigsManutencao"
            )
        ),
        private: true,
      },
      //Fichas Tecnicas
      {
        path: "/fichas-tecnicas",
        element: React.lazy(
          () => import("../pages/FichasTecnicas/FichasTecnicas")
        ),
        private: true,
      },

      //PEC's

      {
        path: "/pecs/list",
        element: React.lazy(() => import("../pages/PECs/List")),
        private: true,
      },
      {
        path: "/pecs/list/:id",
        element: React.lazy(() => import("../pages/PECs/CreatePec")),
        private: true,
      },
      {
        path: "/pecs/maintenance",
        element: React.lazy(
          () => import("../pages/PECs/Maintenance/Maintenance")
        ),
        private: true,
      },
      {
        path: "/pecs/indicators",
        element: React.lazy(
          () => import("../pages/PECs/Indicators/Indicators")
        ),
        private: true,
      },

      // Showroom
      {
        path: "/showroom/maintenance",
        element: React.lazy(
          () => import("../pages/ShowRoom/Maintenace/Maintenance")
        ),
        private: true,
      },
      {
        path: "/showroom/dashboard",
        element: React.lazy(
          () => import("../pages/ShowRoom/Dashboard/Dashboard")
        ),
        private: true,
      },
      {
        path: "/showroom/visualize",
        element: React.lazy(
          () => import("../pages/ShowRoom/Visualizacao/Catalogo")
        ),
        private: true,
      },
      {
        path: "/showroom/visualize/:id",
        element: React.lazy(
          () => import("../pages/PecasClientes/Visualizacao/CriarPeca")
        ),
        private: true,
      },

      // Certificados
      {
        path: "/certificates/configurations",
        element: React.lazy(
          () => import("../pages/Certificates/Configuracoes")
          // redirect (old): https://rd.riopele.pt/login.php?apps&redirect=views/certificados/configuracoes.php
        ),
        private: true,
      },
      {
        path: "/certificates/consult",
        element: React.lazy(
          () => import("../pages/Certificates/Consulta")
          // redirect (old): https://rd.riopele.pt/views/certificados/consulta.php
        ),
        private: true,
      },
      {
        path: "/certificates/list",
        element: React.lazy(
          () => import("../pages/Certificates/ListaCertificados")
          // redirect (old): https://rd.riopele.pt/views/certificados/lista.php
        ),
        private: true,
      },
      {
        path: "/certificates/list/:id",
        element: React.lazy(
          () => import("../pages/Certificates/Certificado")
          // redirect (old): https://rd.riopele.pt/views/certificados/certificado.php
        ),
        private: true,
      },
      {
        path: "/certificates/requisitions",
        element: React.lazy(
          () => import("../pages/Certificates/ListaRequisicoes")
          // redirect (old): https://rd.riopele.pt/views/certificados/lista-requisicoes.php
        ),
        private: true,
      },
      {
        path: "/certificates/consult/:id",
        element: React.lazy(() => import("../pages/Certificates/Detalhes")),
        private: true,
      },

      // Gestão Documental
      {
        path: "/documentmanagement/content",
        element: React.lazy(() => import("../pages/GestaoDocumental/Conteudo")),
        private: true,
      },
      {
        path: "/documentmanagement/content/adddocument/:id",
        element: React.lazy(
          () => import("../pages/GestaoDocumental/AddDocumentoAddVersao")
        ),
        private: "/documentmanagement/content",
      },
      {
        path: "/documentmanagement/content/addversion/:id",
        element: React.lazy(
          () => import("../pages/GestaoDocumental/AddDocumentoAddVersao")
        ),
        private: "/documentmanagement/content",
      },
      {
        path: "/documentmanagement/content/document/:id",
        element: React.lazy(
          () => import("../pages/GestaoDocumental/DetalhesDocumento")
        ),
        private: "/documentmanagement/content",
      },
      {
        path: "/documentmanagement/mydocs",
        element: React.lazy(
          () => import("../pages/GestaoDocumental/OsMeusDocumentos")
        ),
        private: true,
      },
      {
        path: "/documentmanagement/mydocs/help",
        element: React.lazy(() => import("../pages/GestaoDocumental/Ajuda")),
        private: "/documentmanagement/mydocs",
      },
      {
        path: "/documentmanagement/mydocs/:method/:id",
        element: React.lazy(
          () => import("../pages/GestaoDocumental/AprovacaoERevisao")
        ),
        private: "/documentmanagement/mydocs",
      },
      {
        path: "/documentmanagement/maintenance",
        element: React.lazy(
          () => import("../pages/GestaoDocumental/Manutencao")
        ),
        private: true,
      },

      // Procure to Pay (P2P)
      {
        path: "/p2p/list",
        element: React.lazy(() => import("../pages/p2p/ListaRequisicao")),
        private: true,
      },
      {
        path: "/p2p/list/:id",
        element: React.lazy(() => import("../pages/p2p/Requisicao")),
        private: "/p2p/list",
      },
      {
        path: "/p2p/maintenance/approvers",
        element: React.lazy(
          () => import("../pages/p2p/Manutencao/Aprovadores")
        ),
        private: true,
      },
      {
        path: "/p2p/approvals",
        element: React.lazy(() => import("../pages/p2p/Aprovacoes/List")),
        private: true,
      },
      {
        path: "/p2p/approvals/:id",
        element: React.lazy(() => import("../pages/p2p/Requisicao")),
        private: "/p2p/approvals",
      },

      //TimeSheets
      {
        path: "/timesheets/dashboard",
        element: React.lazy(() => import("../pages/TimeSheets/Dashboard")),
        private: true,
      },
      {
        path: "/timesheets/manutencao",
        element: React.lazy(
          () => import("../pages/TimeSheets/Manutencao/TimeSheetsManutencao")
        ),
        private: true,
      },
      {
        path: "/timesheets/projetos",
        element: React.lazy(
          () => import("../pages/TimeSheets/Projetos/TimeSheetsProjetos")
        ),
        private: true,
      },
      {
        path: "/timesheets/projetos/:id",
        element: React.lazy(
          () => import("../pages/TimeSheets/Projetos/ProjectDatails")
        ),
        private: true,
      },

      {
        path: "/tqm/manutencao",
        element: React.lazy(
          () => import("../pages/TQM/Manutencao/TQMManutencao")
        ),
        private: true,
      },

      //RIOPELE 4.0
      {
        path: "/riopele40/monitoring",
        element: React.lazy(
          () => import("../pages/Riopele40/Monitorizacao/Monitorizacao")
        ),
        private: true,
      },

      {
        path: "/riopele40/monitoring/:id",
        element: React.lazy(
          () =>
            import("../pages/Riopele40/Monitorizacao/Seccoes/MonitoresMaquinas")
        ),
        private: "/riopele40/monitoring",
      },

      // REUNIÕES
      {
        path: "/reunioes/list",
        element: React.lazy(() => import("../pages/RegistoReunioes/Lista")),
        private: true,
      },
      {
        path: "/reunioes/manutencao",
        element: React.lazy(
          () => import("../pages/RegistoReunioes/Manutencao/Manutencao")
        ),
        private: true,
      },
      {
        path: "/reunioes/list/:id",
        element: React.lazy(
          () => import("../pages/RegistoReunioes/CreateRegisto")
        ),
        private: true,
      },
      // Cones
      {
        path: "/cones/maintenance",
        element: React.lazy(
          () => import("../pages/Cones/Manutencao/Manutencao")
        ),
        private: false,
      },
      {
        path: "/cones/calculator",
        element: React.lazy(
          () => import("../pages/Cones/Calculadora/Calculadora")
        ),
        private: false,
      },
      // Coleções
      {
        path: "/maintenance/seasons",
        element: React.lazy(
          () => import("../pages/Maintenance/Seasons/Seasons")
        ),
        private: true,
      },

      {
        path: "/maintenance/collections-by-user",
        element: React.lazy(
          () => import("../pages/Maintenance/Seasons/ByUser")
        ),
        private: true,
      },

      // Produtos Químicos

      {
        path: "/chemical-products/products",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Produtos/Produtos")
        ),
        private: true,
      },
      {
        path: "/chemical-products/products/:id",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Produtos/Detalhe")
        ),
        private: true,
      },
      {
        path: "/chemical-products/consult",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Consulta/Consulta")
        ),
        private: true,
      },
      {
        path: "/chemical-products/maintenance",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Manutencao/Manutencao")
        ),
        private: true,
      },

      {
        path: "/chemical-products/reports",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Relatorios/Relatorios")
        ),
        private: true,
      },
      {
        path: "/chemical-products/reports/:id",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Relatorios/Lista")
        ),
        private: true,
      },
      {
        path: "/chemical-products/restrictions",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Restricoes/Restricoes")
        ),
        private: true,
      },
      {
        path: "/chemical-products/restrictions/compare-versions/:id",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Restricoes/CompararVersoes")
        ),
        private: false,
      },
      {
        path: "/chemical-products/restrictions/:id",
        element: React.lazy(
          () => import("../pages/ProdutosQuimicos/Restricoes/Details")
        ),
        private: true,
      },

      // Revista Final
      {
        path: "/rf/maintenance",
        element: React.lazy(
          () => import("../pages/Rf/Maintenance/Maintenance")
        ),
        private: true,
      },

      // Repositorio Digital
      {
        path: "/repository/maintenance",
        element: React.lazy(
          () => import("../pages/RepositorioDigital/Manutencao/Manutencao")
        ),
        private: true,
      },

      // Smart Liquor
      {
        path: "/smartliquor/ensaios",
        element: React.lazy(() => import("../pages/SmartLiquor/Ensaios")),
        private: true,
      },

      // Peças de Cliente
      {
        path: "/customer-garments/maintenance",
        element: React.lazy(
          () => import("../pages/PecasClientes/Manutencao/PcManutencao")
        ),
        private: true,
      },
      {
        path: "/customer-garments/view",
        element: React.lazy(
          () => import("../pages/PecasClientes/Visualizacao/Catalogo")
        ),
        private: true,
      },
      {
        path: "/customer-garments/view/:id",
        element: React.lazy(
          () => import("../pages/PecasClientes/Visualizacao/CriarPeca")
        ),
        private: true,
      },

      // Passagens de Turno
      {
        path: "/passagem-informacao/list/task/new",
        element: React.lazy(
          () => import("../pages/PassagemInformacao/Task/TaskEdit")
        ),
        private: "/passagem-informacao/list",
      },

      {
        path: "/passagem-informacao/manutencao/sections/:id",
        element: React.lazy(
          () => import("../pages/PassagemInformacao/Sections/SectionDetail")
        ),
        private: "/passagem-informacao/manutencao",
      },

      {
        path: "/passagem-informacao/manutencao/task-tag",
        element: React.lazy(
          () => import("../pages/PassagemInformacao/Task-tag/TaskTags")
        ),
        private: "/passagem-informacao/manutencao",
      },

      {
        path: "/passagem-informacao/manutencao/task-tag/:id",
        element: React.lazy(
          () => import("../pages/PassagemInformacao/Task-tag/TaskTagDetail")
        ),
        private: "/passagem-informacao/manutencao",
      },

      {
        path: "/passagem-informacao/list/task/:id",
        element: React.lazy(
          () => import("../pages/PassagemInformacao/Task/TaskDetail")
        ),
        private: "/passagem-informacao/list",
      },
      {
        path: "/passagem-informacao/list",
        element: React.lazy(
          () => import("../pages/PassagemInformacao/Task/Tasks")
        ),
        private: "/passagem-informacao/list",
      },
      {
        path: "/passagem-informacao/manutencao",
        element: React.lazy(
          () => import("../pages/PassagemInformacao/Manutencao/Manutencao")
        ),
        private: "/passagem-informacao/manutencao",
      },

      //PAINEIS
      {
        path: "/panels/list",
        element: React.lazy(() => import("../pages/Paineis/Paineis/Lista")),
        private: true,
      },
      {
        path: "/panels/list/new",
        element: React.lazy(() => import("../pages/Paineis/Paineis/Detalhe")),
        private: true,
      },
      {
        path: "/panels/list/:id",
        element: React.lazy(() => import("../pages/Paineis/Paineis/Detalhe")),
        private: true,
      },
      {
        path: "/panels/capsules",
        element: React.lazy(() => import("../pages/Paineis/Capsulas/Capsulas")),
        private: true,
      },
      {
        path: "/panels/requests",
        element: React.lazy(() => import("../pages/Paineis/Requisitar/Lista")),
        private: true,
      },
      {
        path: "/panels/requests/:id",
        element: React.lazy(
          () => import("../pages/Paineis/Requisitar/Detalhe")
        ),
        private: true,
      },
      {
        path: "/panels/report",
        element: React.lazy(
          () => import("../pages/Paineis/Relatorio/Relatorio")
        ),
        private: true,
      },
      {
        path: "/panels/dashboard",
        element: React.lazy(
          () => import("../pages/Paineis/Dashboard/Dashboard")
        ),
        private: true,
      },
      // Gestão Comercial - CONSULTA DE PREÇOS
      {
        path: "/gestao-comercial/precos",
        element: React.lazy(
          () => import("../pages/GestaoComercial/ConsultaPrecos/Prices")
        ),
        private: true,
      },
      // Gestão Comercial - CONSULTA DE STOCKS
      {
        path: "/gestao-comercial/stocks",
        element: React.lazy(
          () => import("../pages/GestaoComercial/ConsultaStocks/Stocks")
        ),
        private: true,
      },
      //QAS Plano de Trabalho
      {
        path: "/qas/plano-trabalho",
        element: React.lazy(
          () => import("../pages/Qas/PlanoTrabalho/PlanoTrabalho")
        ),
        private: true,
      },
      // QAS Obras
      {
        path: "/qas/obras/lista",
        element: React.lazy(() => import("../pages/Qas/Obras/Lista")),
        private: true,
      },
      {
        path: "/qas/obras/lista/:id",
        element: React.lazy(() => import("../pages/Qas/Obras/Detalhe")),
        private: true,
      },
      {
        path: "/qas/obras/manutencao",
        element: React.lazy(
          () => import("../pages/Qas/Obras/Manutencao/Manutencao")
        ),
        private: true,
      },
      //QAS Ficha do Fornecedor
      {
        path: "/qas/supplier",
        element: React.lazy(
          () => import("../pages/Qas/FichasFornecedores/FichasFornecedores")
        ),
        private: true,
      },
      //QAS Inspeções e Verificações - Manutenção
      {
        path: "/qas/maintenance",
        element: React.lazy(
          () =>
            import("../pages/Qas/InspeçõesVerificacoes/Manutencao/Manutencao")
        ),
        private: true,
      },
      //QAS Inspeções e Verificações - SCIE
      {
        path: "/qas/scie/equipamentos",
        element: React.lazy(
          () => import("../pages/Qas/InspeçõesVerificacoes/SCIE/Equipamentos")
        ),
        private: true,
      },
      {
        path: "/qas/scie/verificacoes",
        element: React.lazy(
          () =>
            import(
              "../pages/Qas/InspeçõesVerificacoes/SCIE/Verificacoes/Verificacoes"
            )
        ),
        private: true,
      },
      //QAS Inspeções e Verificações - ET
      {
        path: "/qas/et/equipamentos",
        element: React.lazy(
          () => import("../pages/Qas/InspeçõesVerificacoes/SCIE/Equipamentos")
        ),
        private: true,
      },
      {
        path: "/qas/et/verificacoes",
        element: React.lazy(
          () =>
            import(
              "../pages/Qas/InspeçõesVerificacoes/SCIE/Verificacoes/Verificacoes"
            )
        ),
        private: true,
      },
      //QAS Constatações
      {
        path: "/qas/constatacoes",
        element: React.lazy(
          () => import("../pages/Qas/Constatacoes/Constatacoes")
        ),
        private: true,
      },
      {
        path: "/qas/constatacoes/new",
        element: React.lazy(
          () => import("../pages/Qas/Constatacoes/NovaConstatacao")
        ),
        private: "/qas/constatacoes",
      },
      // Gestão Comercial - Dashboard Clientes
      {
        path: "/gestao-comercial/:id",
        element: React.lazy(() => import("../pages/GestaoComercial/Dashboard")),
        private: false,
      },
      // Amostras
      {
        path: "/samples/customers/list",
        element: React.lazy(
          () => import("../pages/Amostras/Clientes/Clientes")
        ),
        private: true,
      },

      //

      {
        path: "/media-kit",
        element: React.lazy(
          () => import("../pages/MediaKit/Visualize/Visualize")
        ),
        private: true,
      },

      {
        path: "/media-kit/:id",
        element: React.lazy(
          () => import("../pages/MediaKit/Visualize/Visualize")
        ),
        private: true,
      },

      {
        path: "/samples/customers/list/:id",
        element: React.lazy(() => import("../pages/Amostras/Clientes/Detalhe")),
        private: true,
      },
      {
        path: "/samples/orders/list",
        element: React.lazy(
          () => import("../pages/Amostras/Encomendas/Encomendas")
        ),
        private: true,
      },
      {
        path: "/samples/orders/list/:id",
        element: React.lazy(
          () => import("../pages/Amostras/Encomendas/Detalhe")
        ),
        private: true,
      },
      {
        path: "/samples/repeat-confirmation",
        element: React.lazy(
          () => import("../pages/Amostras/RepetirConfirmacao")
        ),
        private: true,
      },
      {
        path: "/samples/orders-list-exec",
        element: React.lazy(() => import("../pages/Amostras/Execucao/Lista")),
        private: true,
      },
      {
        path: "/samples/orders-list-exec/:id",
        element: React.lazy(() => import("../pages/Amostras/Execucao/Detalhe")),
        private: true,
      },
      {
        path: "/samples/orders-exec-dashboard",
        element: React.lazy(
          () => import("../pages/Amostras/Execucao/Dashboard")
        ),
        private: true,
      },
      {
        path: "/samples/orders-list-id-exec",
        element: React.lazy(() => import("../pages/Amostras/ExecucaoID/Lista")),
        private: true,
      },
      {
        path: "/samples/orders-list-id-exec/:id",
        element: React.lazy(
          () => import("../pages/Amostras/ExecucaoID/Detalhe")
        ),
        private: true,
      },
      {
        path: "/samples/orders-list-id-dashboard",
        element: React.lazy(
          () => import("../pages/Amostras/ExecucaoID/Dashboard")
        ),
        private: true,
      },
      {
        path: "/samples/orders-list-exp",
        element: React.lazy(() => import("../pages/Amostras/Expedicao/Lista")),
        private: true,
      },
      {
        path: "/samples/orders-list-exp/:id",
        element: React.lazy(
          () => import("../pages/Amostras/Expedicao/Detalhe")
        ),
        private: true,
      },
      {
        path: "/samples/orders-list-dashboard-exp",
        element: React.lazy(
          () => import("../pages/Amostras/Expedicao/Dashboard")
        ),
        private: true,
      },
      {
        path: "/samples/samples-dashboard",
        element: React.lazy(() => import("../pages/Amostras/Dashboard")),
        private: true,
      },
      {
        path: "/samples/samples-statistics",
        element: React.lazy(() => import("../pages/Amostras/DashboardGeral")),
        private: true,
      },
      {
        path: "/samples/artigos-sap",
        element: React.lazy(() => import("../pages/Amostras/Artigos/Artigos")),
        private: true,
      },
      {
        path: "/samples/artigos-sap/:id",
        element: React.lazy(() => import("../pages/Amostras/Artigos/Detalhe")),
        private: true,
      },
      {
        path: "/samples/orders-redirect",
        element: React.lazy(
          () => import("../pages/Amostras/RedirecionarPedidos")
        ),
        private: true,
      },
      {
        path: "/samples/orders-redirect",
        element: React.lazy(
          () => import("../pages/Amostras/RedirecionarPedidos")
        ),
        private: true,
      },
      {
        path: "/samples/orders-list-conf",
        element: React.lazy(() => import("../pages/Amostras/ConfirmarPedidos")),
        private: true,
      },
      {
        path: "/samples/samples-report-cliente",
        element: React.lazy(
          () => import("../pages/Amostras/Consultas/AmostrasPorCliente")
        ),
        private: true,
      },
      {
        path: "/samples/samples-report-boxs",
        element: React.lazy(() => import("../pages/Amostras/Consultas/Boxs")),
        private: true,
      },
      {
        path: "/samples/samples-report-docs-suporte",
        element: React.lazy(
          () => import("../pages/Amostras/Consultas/DocsSuporte")
        ),
        private: true,
      },
      {
        path: "/samples/samples-report-artigos-col",
        element: React.lazy(
          () => import("../pages/Amostras/Consultas/TabelaColecao")
        ),
        private: true,
      },
      {
        path: "/samples/samples-report-pks",
        element: React.lazy(
          () => import("../pages/Amostras/Consultas/ExportarPks")
        ),
        private: true,
      },
      {
        path: "/samples/artigos-etiquetas",
        element: React.lazy(() => import("../pages/Amostras/Etiquetas")),
        private: true,
      },

      // Auditorias
      {
        path: "/auditorias/lista/iniciaAuditoria",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoria/IniciaProcedimentoAuditoria"
            )
        ),
        private: "/auditorias/lista",
      },
      {
        path: "/auditorias/consultas",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoria/ProcedimentosAuditoriaListReporting"
            )
        ),
        private: "/auditorias/consultas",
      },
      {
        path: "/auditorias/manutencao",
        element: React.lazy(
          () => import("../pages/Auditorias/Manutencao/Manutencao")
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/area-auditoria/:id",
        element: React.lazy(
          () => import("../pages/Auditorias/AreaAuditoria/AreaAuditoriaDetail")
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/area-auditoria/new",
        element: React.lazy(
          () => import("../pages/Auditorias/AreaAuditoria/AreaAuditoriaDetail")
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/circuito-auditoria/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/CircuitoAuditoria/CircuitoAuditoriaDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/circuito-auditoria/new/:idArea",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/CircuitoAuditoria/CircuitoAuditoriaDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/tipo-anotacao/:id",
        element: React.lazy(
          () => import("../pages/Auditorias/TipoAnotacao/TipoAnotacaoDetail")
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/tipo-anotacao/new/:idArea",
        element: React.lazy(
          () => import("../pages/Auditorias/TipoAnotacao/TipoAnotacaoDetail")
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/procedimento-auditoria/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoria/ProcedimentoAuditoriaDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/procedimento-auditoria/new/:idCircuito",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoria/ProcedimentoAuditoriaDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/procedimento-auditoria/gestao/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoria/ProcedimentoAuditoriaGestao"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/tipo-anotacao-item/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/TipoAnotacaoItem/TipoAnotacaoItemDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/tipo-anotacao-item/new/:idTipoAnotacao",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/TipoAnotacaoItem/TipoAnotacaoItemDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/procedimento-grupo/gestao/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoGrupo/ProcedimentoGrupoGestao"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/lista/procedimento-grupo/edicao/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoGrupo/ProcedimentoGrupoEdicao"
            )
        ),
        private: "/auditorias/lista",
      },
      {
        path: "/auditorias/manutencao/procedimento-criterio/gestao/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoCriterio/ProcedimentoCriterioDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/lista/procedimento-auditoria/execucao/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoria/ProcedimentoAuditoriaExecucao"
            )
        ),
        private: "/auditorias/lista",
      },
      {
        path: "/auditorias/lista/procedimento-grupo/execucao/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoGrupo/ProcedimentoGrupoExecucao"
            )
        ),
        private: "/auditorias/lista",
      },
      {
        path: "/auditorias/lista/procedimento-grupo/execucao-tipo3/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoGrupo/ProcedimentoGrupoExecucaoTipo3"
            )
        ),
        private: "/auditorias/lista",
      },
      {
        path: "/auditorias/lista",
        element: React.lazy(() => import("../pages/Auditorias/List/List")),
        private: "/auditorias/lista",
      },
      {
        path: "/auditorias/lista/procedimento-criterio-anotacao/intervencao/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoCriterioAnotacao/IntervencaoDetail"
            )
        ),
        private: "/auditorias/lista",
      },
      {
        path: "/auditorias/manutencao/procedimento-auditoria-user/new",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoriaUser/ProcedimentoAuditoriaUserDetail"
            )
        ),
        private: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/manutencao/procedimento-auditoria-user/:id",
        element: React.lazy(
          () =>
            import(
              "../pages/Auditorias/ProcedimentoAuditoriaUser/ProcedimentoAuditoriaUserDetail"
            )
        ),
        prrivate: "/auditorias/manutencao",
      },
      {
        path: "/auditorias/plano",
        element: React.lazy(() => import("../pages/Auditorias/Plano/Plano")),
        private: true,
      },

      //CONTABILIDADE
      {
        path: "/contabilidade/manutencao",
        element: React.lazy(
          () =>
            import("../pages/Contabilidade/Manutencao/ManutencaoContabilidade")
        ),
        private: true,
      },
      {
        path: "/contabilidade/adiantamentos",
        element: React.lazy(
          () => import("../pages/Contabilidade/Adiantamento/Lista")
        ),
        private: true,
      },
      {
        path: "/contabilidade/adiantamentos/:id",
        element: React.lazy(
          () => import("../pages/Contabilidade/Adiantamento/CriarPedido")
        ),
        private: true,
      },
      {
        path: "/contabilidade/mapa-despesas",
        element: React.lazy(
          () => import("../pages/Contabilidade/MapaDespesas/Lista")
        ),
        private: true,
      },
      {
        path: "/contabilidade/mapa-despesas/:id",
        element: React.lazy(
          () => import("../pages/Contabilidade/MapaDespesas/CriarPedido")
        ),
        private: true,
      },

      // Gestão de Bandeiras
      {
        path: "/gestao-bandeiras/settings",
        element: React.lazy(
          () => import("../pages/GestaoBandeiras/Settings/Settings")
        ),
        private: true,
      },
      {
        path: "/gestao-bandeiras/list",
        element: React.lazy(() => import("../pages/GestaoBandeiras/Bandeiras")),
        private: true,
      },
      {
        path: "/gestao-bandeiras/list/:id",
        element: React.lazy(() => import("../pages/GestaoBandeiras/Bandeiras")),
        private: true,
      },
    ];

    return allRoutes.map((route: any) => {
      if (
        route.private &&
        !menus.some((menu: any) =>
          route.private && route.private.length
            ? menu.rota === route.private
            : menu.rota === route.path.split("/:id")[0]
        )
      ) {
        route.element = () => Navigate({ to: "/404" });
      }
      return route;
    });
  }, [menus]);

  return (
    <Routes>
      {routes.map((Obj, index: any) => {
        return (
          <Route
            key={index}
            path={Obj.path}
            element={
              <Suspense fallback={<TopBarProgress />}>
                {/*eslint-disable-next-line*/}
                <Obj.element />
              </Suspense>
            }
          />
        );
      })}
      <Route path="/404" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/start" />} />
    </Routes>
  );
};

export default PrivateRoutes;
